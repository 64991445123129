export const Colors = {
  Cprimary: "#D30E3A",
  Csecondary: "#ffeeee",
  Ctercer: "#FBF2D4",
  Ccuarto: "#E8EDFF",
  Cquito: "#1f99f0",
  Active: "#385FEA",
  Green: "#1cb803",
};

export const Colorss = {
  // primary: "#000000",
  // primary: "#f42a54",
  secondary: "#CCC",
  ligth: "#FFFF",
  gray: "#ADB3B3",
  dark: "#4B4C4C",
  blueIphone: "#0000FF",
  Cprimary: "#D30E3A",
  Csecondary: "#f42a54",
  Ctercer: "#72c1ff",
  Ccuarto: "#1f99f0",
  Cquito: "#eef8ff",
};
