import GridProductList from "./GridProductList";

const ShopSections = () => {
  return (
    <div className="container  " style={{ paddingTop: "10px" }}>
      <GridProductList />
    </div>
  );
};

export default ShopSections;
