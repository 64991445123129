import React from "react";
import { Colors } from "../../utils/colors";
import { useQuery } from "@tanstack/react-query";
import { getAllSettings } from "../../utils/graphqlFunctions";

const Portada = () => {
  const { data } = useQuery(["Settings"], getAllSettings);
  if (window.innerWidth < 576)
    return (
      <div
        className=" m-3 py-2 "
        style={{ backgroundColor: Colors.Ccuarto, borderRadius: "3%" }}
      >
        <div className="container">
          <div className="row " style={{ minHeight: "11rem" }}>
            <div className="col-6  py-2 col-sm-6 d-flex  justify-content-center aling-items-center container ">
              <div className=" ">
                <div className="text-start">
                  <h1
                    className="mb-4"
                    style={{ fontWeight: "bolder", fontSize: "1.5rem" }}
                  >
                    Lara Hogar Plastic
                  </h1>
                </div>
                <h5 style={{ fontSize: "1rem", fontWeight: "bold" }}>
                  Buenos precios
                </h5>
                <button
                  className="btn  mt-3 text-white "
                  style={{
                    position: "absolute",
                    zIndex: "",
                    backgroundColor: Colors.Cprimary,
                    fontWeight: "bold",
                  }}
                >
                  Disfruta nuestras ofertas !
                </button>
              </div>
            </div>
            <div className="col-6  ">
              <div className="d-flex justify-content-center aling-items-center  ">
                {Array.isArray(data) &&
                data.length > 0 &&
                data[0]?.coverImage?.length > 0 ? (
                  <img
                    src={
                      data[0].coverImage[0]?.url ||
                      "https://img.freepik.com/vector-premium/vector-icono-imagen-predeterminado-pagina-imagen-faltante-diseno-sitio-web-o-aplicacion-movil-no-hay-foto-disponible_87543-11093.jpg"
                    }
                    alt="cover"
                    className="img-fluid"
                  />
                ) : (
                  <img
                    src="https://img.freepik.com/vector-premium/vector-icono-imagen-predeterminado-pagina-imagen-faltante-diseno-sitio-web-o-aplicacion-movil-no-hay-foto-disponible_87543-11093.jpg"
                    alt="default cover"
                    className="img-fluid"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );

  return (
    <div
      className="py-3 m-4 rounded twhiteext- "
      style={{ backgroundColor: Colors.Ccuarto }}
    >
      <div
        className={
          window.innerWidth < 768
            ? "mx-5  "
            : " container d-flex justify-content-center "
        }
      >
        <div
          className="d-flex  justify-content-center flex-column-reverse flex-md-row"
          style={
            window.innerWidth < 1200 ? { width: "100%" } : { width: "70%" }
          }
        >
          <div className="    d-flex flex-column justify-content-center align-items-center  container ">
            <div className=" ">
              <div className="text-start">
                <h2 style={{ fontWeight: "bold" }}>Lara Hogar Plastic</h2>
              </div>
              <h4>Los mejores articulos del hogar en tu tienda favorita</h4>
              {/* <button className="btn btn-dark mt-3">Comprar Ahora</button> */}
            </div>
          </div>
          <div className=" m-auto ">
            <div className="d-flex  justify-content-start  align-items-center ">
              {Array.isArray(data) && data.length > 0 && (
                <img
                  src={
                    Array.isArray(data) &&
                    data.length > 0 &&
                    Array.isArray(data[0]?.coverImage) &&
                    data[0]?.coverImage.length > 0 &&
                    data[0]?.coverImage[0]?.url
                      ? data[0].coverImage[0].url
                      : "https://img.freepik.com/vector-premium/vector-icono-imagen-predeterminado-pagina-imagen-faltante-diseno-sitio-web-o-aplicacion-movil-no-hay-foto-disponible_87543-11093.jpg"
                  }
                  alt="cover"
                  style={{ maxWidth: "20rem" }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Portada;
